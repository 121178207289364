import React, { useEffect } from "react";
import { connect } from "react-redux";
// import { useRouter } from "next/router";
import { _metadata } from "../../utils/local-data";
// import { useAppStoreContext } from "../AppStoreContext";
import { Toaster } from "react-hot-toast";
import __variables from "models/__variables";
import { useRouter } from "next/router";

function Layout({ ...props }) {
   // const { setLoading } = useAppStoreContext();
   const router = useRouter();

   useEffect(() => {
      if (__variables.env === "PRODUCTION") return;
      setTimeout(() => {
         const nextjsPortal = document.querySelector("nextjs-portal");
         if (nextjsPortal) {
            document.body.style.overflow = "unset";
            setTimeout(() => {
               document.body.style.overflow = "unset";
            }, 200);
         }
      }, 2000);
   }, [router]);

   // useEffect(() => {
   // const handleRouteChangeStart = () => {
   //    console.log("router change");
   //    // setLoading(true);
   //    // document.body.classList.add("overflow-hidden");
   //    // document.querySelector("html")?.classList.add("overflow-hidden");
   // };
   // const handleRouteChangeComplete = () => {
   //    console.log("router end");
   //    // setLoading(false);
   //    // document.body.classList.remove("overflow-hidden");
   //    // document.querySelector("html")?.classList.remove("overflow-hidden");
   // };

   // router.events.on("routeChangeStart", handleRouteChangeStart);
   // router.events.on("routeChangeError", handleRouteChangeComplete);
   // router.events.on("routeChangeComplete", handleRouteChangeComplete);

   // return () => {
   //    router.events.off("routeChangeStart", handleRouteChangeStart);
   //    router.events.off("routeChangeError", handleRouteChangeComplete);
   //    router.events.off("routeChangeComplete", handleRouteChangeComplete);
   // };
   // }, []);

   // useEffect(() => {
   //    let prevScrollpos = 0;
   //    function handleScroll() {
   //       if (window.innerWidth < 768) {
   //          const currentScrollPos = window.pageYOffset;
   //          const navElm = document.getElementById("header");
   //          const hierarchical = document.getElementById("hierarchical");
   //          if (hierarchical) {
   //             if (currentScrollPos > 100) {
   //                hierarchical && (hierarchical.style.display = "flex");
   //             } else {
   //                hierarchical && (hierarchical.style.display = "none");
   //             }
   //          } else if (navElm) {
   //             if (prevScrollpos <= currentScrollPos) {
   //                navElm.style.position = "relative";
   //             } else {
   //                navElm.style.position = "sticky";
   //                navElm.style.top = "0";
   //             }
   //          }
   //          prevScrollpos = currentScrollPos;
   //       }
   //    }
   //    window.addEventListener("scroll", handleScroll);
   //    return () => window.removeEventListener("scroll", handleScroll);
   // }, []);

   return (
      <>
         {props?.children}
         <Toaster position="top-center" toastOptions={{ className: "react-hot-toast" }} />
      </>
   );
}
export default connect((props) => props)(Layout);
