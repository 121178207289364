import Image from "next/image";
import React from "react";

interface IProps {
   loginPhone: () => void;
   loginEmail: () => void;
   loginFacebook: () => void;
   loginGoogle: () => void;
   // loginApple: () => void;
   onRegister: () => void;
}

const SigninStep1: React.FC<IProps> = ({
   loginPhone,
   loginEmail,
   loginFacebook,
   loginGoogle,
   //  loginApple,
   onRegister,
}) => {
   // const _hideAppleLoginiOSDevice = typeof window !== "undefined" && window.innerWidth < 768 && !navigator.platform.match(/iPhone|iPod|iPad/);
   return (
      <div className="register-step">
         <div className="d-flex flex-column justify-content-center">
            <div className="mobile_title d-flex d-md-none justify-content-center flex-column align-items-center">
               <Image src="/assets/logo.png" width={184} height={44} alt="Space T Việt Nam" title="Space T Việt Nam" />
               <h2>Nền tảng nội thất tất cả trong một</h2>
               <p>(Cộng đồng, thương mại và xây dựng)</p>
            </div>
            <h1 className="title_sign_up">ĐĂNG NHẬP</h1>
            <div className="type_sign_up" onClick={loginPhone}>
               <div className="img">
                  <img width={18} height={18} src={"/assets/img/icon/phone_icon.svg"} alt="Đăng nhập bằng GG" />
               </div>
               <div className="text">Tiếp tục với Số điện thoại</div>
            </div>
            <div className="type_sign_up" onClick={loginEmail}>
               <div className="img">
                  <img width={18} height={18} src={"/assets/img/icon/mail_icon.svg"} alt="Đăng nhập bằng GG" />
               </div>
               <div className="text">Tiếp tục với địa chỉ email</div>
            </div>
            <div className="type_sign_up" onClick={loginFacebook}>
               <div className="img">
                  <img width="18px" height="18px" src="/assets/img/icon/login_FB.svg" alt="Đăng nhập bằng GG" />
               </div>
               <div className="text">Tiếp tục với Facebook</div>
            </div>
            <div className="type_sign_up" onClick={loginGoogle}>
               <div className="img">
                  <img width={18} height={18} src="/assets/img/icon/login_G.svg" alt="Đăng nhập bằng GG" />
               </div>
               <div className="text">Tiếp tục với Google</div>
            </div>
            {/* {_hideAppleLoginiOSDevice ? (
               ""
            ) : (
               <div className="type_sign_up" onClick={loginApple}>
                  <div className="img">
                     <img width={18} height={18} src="/assets/img/icon/apple_icon.svg" alt="Đăng nhập bằng GG" />
                  </div>
                  <div className="text">Tiếp tục với Apple</div>
               </div>
            )} */}

            <div className="register d-flex justify-content-center">
               Bạn chưa có tài khoản?{" "}
               <div
                  className="pointer ms-1"
                  style={{ color: "#FF6347", cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => {
                     onRegister();
                  }}
               >
                  Đăng ký ngay
               </div>
            </div>

            <div className="noti">
               <span>Tôi đã đọc và chấp nhận các </span>
               <a style={{ color: "#252A2B" }} href="/chinh-sach/dieu-khoan-dich-vu">
                  Điều khoản sử dụng,{" "}
               </a>
               <a style={{ color: "#252A2B" }} href="/chinh-sach/chinh-sach-bao-mat">
                  Chính sách bảo mật
               </a>
               <span> và </span>
               <a style={{ color: "#252A2B" }} href={"/chinh-sach/chinh-sach-giai-quyet-khieu-nai"}>
                  chính sách giải quyết khiếu nại
               </a>
               <span> của SPACE T và đồng ý đăng ký làm thành viên </span>
            </div>
         </div>
      </div>
   );
};

export default SigninStep1;
