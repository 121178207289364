import { userInfoKey } from "@Config/index";
import { tokenKey } from "@Models/__variables";
import Cookies from "js-cookie";

export function setProfileCache(profile) {
   localStorage.setItem(userInfoKey, JSON.stringify(profile));
}
export function clearProfileCache() {
   Cookies.remove(tokenKey);
   localStorage.removeItem(tokenKey);
   localStorage.removeItem(userInfoKey);
} 