import { apiDomain, appDomain, isDev } from "./__variables";
import { Model, IModel, ModelError, TModelError, TPayload } from "./model";
import MenuItem, { IMenuItem } from "./menuItem.model";
import menuJson from "../../public/assets/__data/menu.prod.json";

export enum EStatus {
   enable = "enable",
   disable = "disable"
}

// export enum EMenu {
//    socialFeed = "Feed",
//    communityPost = "Tổ ấm",
//    qna = "Hỏi đáp"
// }

export interface IMenu extends IModel {
   ["info"]: {
      ["name"]: string;
      ["alias"]: string;
   };
   ["options"]: {
      ["status"]: EStatus;
   };

   ["__items"]: MenuItem[];
}

export class Menu extends Model<IMenu> implements IMenu {
   ["info"]!: {
      ["name"]: string;
      ["alias"]: string;
   };
   ["options"]!: {
      ["status"]: EStatus;
   };

   ["__items"]: MenuItem[];

   constructor(data?: IMenu) {
      super(data);
      Object.assign(this, data);
      if (this.__items && this.__items instanceof Array) {
         for (let i = 0; i < this.__items.length; i++) {
            const item = this.__items[i];

            if (!(item instanceof MenuItem) && typeof item === "object") {
               this.__items[i] = new MenuItem(item, null);
            }
         }
      }
   }

   public static findItemWithId(arr: MenuItem[], itemId): MenuItem | undefined {
      for (let item of arr) {
         if (item._id === itemId) {
            return item;
         }
         if (item.__childs) {
            let foundItem = this.findItemWithId(item.__childs, itemId);
            if (foundItem) {
               return foundItem;
            }
         }
      }
   }
   public static findItemWithSlug(arr: MenuItem[] = [], slug): IMenuItem | undefined {
      if (!arr) return
      return this.findItemWithSlugRecursive(arr, slug);
   }
   public static findItemWithSlugV2(arr: MenuItem[] = [], asPath): IMenuItem | undefined {
      if (!arr || arr.length <= 0 || !asPath) return
      let slug;
      const firstPath = asPath.split("/");
      if (firstPath[1] === "shop" && firstPath[2]) {
         slug = asPath.split("/")[2];
      } else if (firstPath[1] === "blog" && firstPath[2]) {
         slug = asPath.split("/")[2];
      } else {
         slug = asPath.split("/")[1];
      }
      return this.findItemWithSlugRecursive(arr, slug);
   }
   public static findItemWithSlugRecursive(arr?: IMenuItem[], slug?: string): IMenuItem | undefined {
      if (!arr || !slug) return
      for (let item of arr) {
         if (item.info.slug === slug) {
            return item;
         }
         if (item.__childs) {
            let foundItem = this.findItemWithSlugRecursive(item.__childs, slug);
            if (foundItem) {
               return foundItem;
            }
         }
      }
   }
   public static findMultilItemWithSlugRecursive(arr?: IMenuItem[], slug?: any[]): IMenuItem[] {
      if (!arr || !slug) return []
      const data = [] as IMenuItem[]
      for (let item of arr) {
         if (slug.includes(item.info.slug)) {
            data.push(item);
         }
         if (item.__childs) {
            let foundItem = this.findMultilItemWithSlugRecursive(item.__childs, slug);
            if (foundItem) {
               data.push(...foundItem);
            }
         }
      }
      return data
   }
   /**
    *
    * @param alias
    * @returns
    */
   public static async getMenuByAlias(alias: string) {
      try {
         // const response = await fetch(`${apiDomain}/www/menu`, {
         //    ["method"]: "GET",
         //    ["cache"]: "no-store",
         //    ["headers"]: {
         //       ["Origin"]: "https://spacet.vn",
         //       ["X-Requested-With"]: "XMLHttpRequest",
         //       ["alias"]: encodeURI(alias)
         //    }
         // });
         // if (!response.ok) {
         //    return new ModelError((await response.json()) as TModelError);
         // }

         // const payload = (await response.json()) as TPayload<{
         //    ["menu"]: IMenu;
         // }>;
         // return new Menu(payload.data.menu);
         return new Menu(menuJson as any);
      } catch (error) {
         console.error(error);
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling."
                  }
               ]
            }
         });
      }
   }
   public static async menu({ alias, type }: { alias: string; type?: string }) {
      try {
         const response = await fetch(`${apiDomain}/www/menu`, {
            ["method"]: "GET",
            // ["cache"]: "no-store",
            // ["cache"]: "force-cache", // TypeScript 5.1.3trở lên và @types/react 18.2.8cao hơn.
            // ["next"]: { revalidate: 60 * 60 * 24 }, TypeScript 5.1.3trở lên và @types/react 18.2.8cao hơn.
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ["alias"]: encodeURI(alias),
               ...(type !== undefined ? { ["type"]: type } : null)
            }
         });
         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["menu"]: IMenu;
         }>;
         return payload.data.menu as Menu;
      } catch (error) {
         console.error(error);
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling."
                  }
               ]
            }
         });
      }
   }

   /**
    * This method can return menu old if any error in fetch the process 
    * @returns 
    */
   public static async getMenuList(): Promise<IMenu> {
      try {
         const res = await fetch(`${appDomain}/assets/__data/` + (isDev ? "menu.stag.json" : "menu.prod.json"), {
            ["method"]: "GET",
            ["cache"]: "no-store",
            // ["cache"]: "force-cache",
            // ["next"]: { revalidate: 60 * 60 * 24 },
            ["headers"]: {
               "content-type": "application/json"
            }
         });

         if (!res.ok) {
            return menuJson as any
         }

         const payload = await res.json()

         return payload as IMenu;
      } catch (error) {
         return menuJson as any
      }
   }
   public static async getBreadcumbBySlug({ slug, productSlug }: { slug?: string; productSlug?: string }) {
      try {
         // console.time("breadcrumb");
         const response = await fetch(`${apiDomain}/www/menu/breadcrumb-by-slug`, {
            ["method"]: "GET",
            ["cache"]: "no-store",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ...(!slug ? undefined : { slug: encodeURI(slug.toString()) }),
               ...(!productSlug ? undefined : { "product-slug": encodeURI(productSlug.toString()) })
            }
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }
         // console.timeEnd("breadcrumb");

         const payload = await response.json();
         return payload.data.breadcrum;
      } catch (error) {
         console.error(error);
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling."
                  }
               ]
            }
         });
      }
   }
}

export default Menu;
