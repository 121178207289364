import { Fragment, useState } from "react";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Button from "@Components/button";
import useCustomHookState from "hook/useCustomHookState";

export default function SchedulePost({ value, onBack, onSubmit, onClose }: { value?: string; onBack?: any; onSubmit?: any; onClose?: any }) {
   const initDate = moment(new Date().getTime() + 1000 * 60 * 30);
   const [date, setDate] = useState<any>(value ? moment(value) : initDate);
   const { state, setState } = useCustomHookState<{
      loading?: boolean;
   }>({});
   const handleSubmit = async () => {
      setState({ loading: true });
      await onSubmit(moment(date).toLocaleString());
      setState({ loading: false });
   };
   const handleChangeDate = (newValue) => {
      const currentTime = new Date().getTime();
      const postTime = moment(newValue).toDate().getTime();
      if (postTime < currentTime) {
         setDate(moment(currentTime));
      } else {
         setDate(moment(newValue));
      }
   };
   return (
      <div className={"schedule-post-dialog"}>
         <div className="d-flex align-items-center justify-content-between p-3 border-bottom">
            <span className="fs-20 fw-500 lh-28">Lên lịch đăng bài</span>
            <span onClick={onClose}>
               <svg className="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                     d="M13.1665 12.008L20.762 4.43822C21.0793 4.11791 21.0793 3.599 20.762 3.27868C20.4503 2.95264 19.9355 2.94285 19.6118 3.2568L12.0163 10.8266L4.51839 3.2568C4.36467 3.09288 4.15078 3 3.92702 3C3.70327 3 3.48938 3.09288 3.33566 3.2568C3.0543 3.56628 3.0543 4.04123 3.33566 4.35071L10.8335 11.9096L3.238 19.4685C2.92067 19.7888 2.92067 20.3077 3.238 20.628C3.38907 20.784 3.59685 20.871 3.81309 20.8687C4.03351 20.8867 4.25202 20.8159 4.42074 20.6718L12.0163 13.102L19.6118 20.7593C19.7629 20.9153 19.9707 21.0022 20.1869 21C20.4029 21.001 20.6102 20.9142 20.762 20.7593C21.0793 20.439 21.0793 19.9201 20.762 19.5998L13.1665 12.008Z"
                     fill="black"
                  />
               </svg>
            </span>
         </div>

         <div className="d-flex w-100 px-3 py-4">
            <Button width="100%" variant="bt-outline-primary" className="me-2" onClick={onBack}>
               Quay lại
            </Button>
            <Button width="100%" loading={state.loading} className="ms-2" onClick={handleSubmit}>
               Hoàn tất
            </Button>
         </div>

         <div className="px-3 pb-2">
            <div className="block bg-gray rounded text-center p-2">
               <span className="text-center fs-16">
                  Chia sẻ của bạn sẽ được đăng vào lúc <span className=" fw-500">{moment(new Date(date)).format("DD/MM/YYYY, HH:mm")}</span>
               </span>
            </div>
         </div>

         <div className={"dialog-content"}>
            <div className="wrap-datetime">
               <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                     className="schedule-date-time-custom"
                     open
                     // label="Basic date time picker"
                     reduceAnimations
                     slots={{
                        actionBar: () => <Fragment />,
                     }}
                     ampm={false}
                     timeSteps={{ minutes: 1 }}
                     value={date}
                     defaultValue={initDate}
                     disablePast
                     slotProps={{
                        popper: {
                           className: "root-popper-custom",
                        },
                        desktopPaper: {
                           className: "desktop-paper-custom",
                        },
                        layout: {
                           className: "layout-custom",
                        },
                        day: {
                           sx: {
                              "&.Mui-selected": {
                                 color: "#007AFF !important",
                                 background: "#a1cbf7 !important",
                              },
                           },
                        },
                        digitalClockSectionItem: {
                           sx: {
                              "&.Mui-selected": {
                                 color: "#007AFF !important",
                                 background: "#a1cbf7 !important",
                              },
                           },
                        },
                     }}
                     // views={["year", "month", "day", "hours", "minutes"]}
                     dayOfWeekFormatter={(date) => moment(date).format("dd")}
                     onChange={handleChangeDate}
                  />
               </LocalizationProvider>
            </div>
         </div>
      </div>
   );
}
