import classNames from "classnames";
import MenuItem from "models/menuItem.model";
import Link from "@Components/Link";
import { shopmenuId } from "@Config/main";

interface IProps {
   menu: MenuItem;
   isAvailableLevel?: boolean;
   active?: boolean;
   [t: string]: any;
}

export default function ShopMenuItem({ menu, active, isAvailableLevel, ...props }: IProps) {
   if (!menu) return <></>;
   const isShop = menu.xpath?.split(";;").includes(shopmenuId);
   return (
      <Link
         id={menu.info.slug || ""}
         href={isShop ? `/shop/${menu.info.slug}` : `/${menu.info.slug}`}
         className={classNames("shop-menu-item", active ? "active" : "")}
         {...props}
      >
         <div className="d-flex align-items-center justify-content-between">
            {isShop && (
               <div className="menu-icon">
                  <img src={menu.__icon} alt="" />
               </div>
            )}
            <span className="menu-title">{menu.info.title}</span>
         </div>
         {isAvailableLevel && (
            <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none">
               <path d="M5 6L1 10L1 2L5 6Z" fill="#FF6347" stroke="#FF6347" strokeLinecap="round" />
            </svg>
         )}
      </Link>
   );
}
